import React, { useContext } from 'react';
import AuthContext from '../../contexts/auth';
import A from './A';
import Thumbnail from './Thumbnail';
import useLoginRoute from '../../hooks/useLoginRoute';

const Nav = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const loginRoute = useLoginRoute();

  return (
    <nav className="p-5 flex justify-between">
      <div>
        <A href='/app'>
          <Thumbnail width={50} url="/static/img/logo.png" />
        </A>
      </div>
      <div>
        {authContext.loading && <span>...</span>}
        {!authContext.loading && authContext.authenticated &&
          <div className="flex">
            <Thumbnail width={30} url={user.picture} />
            <a href="/api/auth/logout">Logout</a>
          </div>
        }
        {!authContext.loading && !authContext.authenticated &&
          <div className="p-5">
            <a className="btn btn-blue mx-1" href={loginRoute}>Login</a>
          </div>
        }
      </div>
    </nav>
  );
}
export default Nav;
