import React from 'react';

type User = {
  id: string,
  name: string,
  picture: string,
}

interface IAuthContext {
  user?: User;
  loading: boolean;
  authenticated: boolean;
  // setUser: (user: User) => void;
  // setLoading: (loading: boolean) => void;
}

const initialContext: IAuthContext = {
  loading: false,
  authenticated: false,
}

export default React.createContext(initialContext);
