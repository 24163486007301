import React from 'react';

export default props => (
  <div
    className="bg-cover bg-center"
    style={{
      width: `${props.width}px`,
      height: `${props.height ? props.height : props.width}px`,
      backgroundImage: `url('${props.url}')`
    }}
  />
);
